<template>
	<page>
		<module>
			<c-form ref="form" column="2" unit-width="95%" style="max-width:1100px">
				<c-title>基本信息</c-title>
				<c-form-item label="盘点单号">
					<c-input name="inventory_number" value="自动生成" disabled></c-input>
				</c-form-item>
				<c-form-item label="仓库" >
					<c-select name="warehouse_id" options="WAREHOUSE_LIST" disabled></c-select>
				</c-form-item>
				<c-form-item label="盘点原因" >
					<c-select :options="DIC_INVENTORY_REASON" name="reason_code" disabled></c-select>
				</c-form-item>
				<c-form-item label="操作员工" >
					<c-input type="hidden" name="yg_id"></c-input>
					<div class="flex-center">
						<c-input name="yg_name"  maxlength="50" readonly disabled></c-input>
					</div>
				</c-form-item>
				<c-form-item label="说明备注">
					<c-input name="remark" disabled></c-input>
				</c-form-item>
				<c-title>盘点单{{this.warehouse_type == 1?"产品":"物料"}}明细</c-title>
				<!--展示订单待派工的产品列表-->
				<module enable-flex>
					<c-table ref="product_table" :data="productData" :paging="false" border>
<!--						<c-table-column
							:label="warehouse_type==1?'产品编码':'物料编码'"
							name="product_code"
							width="100"
						>
						</c-table-column>-->
					
						<c-table-column
							:label="warehouse_type==1?'产品名称':'物料名称'"
							name="product_name"
							width="300"
						>
						</c-table-column>
						
						<c-table-column
							label="类型"
							name="product_type"
							width="80"
						>
							<template #default="{data}">
							    <span v-if="data.product_type==1">产品</span>
								<span v-if="data.product_type==2">物料</span>
							</template>
						</c-table-column>
						
						<c-table-column
							label="颜色"
							name="product_spec"
							width="150"
						>
							<template #default="{data}">
							    <span v-if="data.product_spec">{{data.product_spec}}</span>
								<span v-else>--</span>
							</template>
						</c-table-column>
					
						<c-table-column
							label="库存数量"
							name="stock_count"
							width="80"
						>
							<template #default="{data}">
							    <span v-if="detailData.state == -2">{{data.db_stock_count}}</span>
								<span v-else-if="detailData.state == 1">{{data.stock_count}}</span>
								<span v-else>0</span>
							</template>
						</c-table-column>
					
						<c-table-column
							label="盘点数量"
							name="product_count"
							width="80"
						>
						</c-table-column>
						
						<c-table-column
							label="盘盈数量"
							name="add_count"
							width="80"
						>
							<template #default="{data}">
							    <span v-if="detailData.state == 1 && data.add_count > 0" style="color:green">{{data.add_count}}</span>
								<span v-else>--</span>
							</template>
						</c-table-column>
						
						<c-table-column
							label="盘亏数量"
							name="add_count"
							width="80"
						>
							<template #default="{data}">
							    <span v-if="detailData.state == 1 && data.add_count < 0" style="color:red">{{-data.add_count}}</span>
								<span v-else>--</span>
							</template>
						</c-table-column>
						
						<c-table-column
							label="单位"
							name="unit"
							width="50"
						>
						</c-table-column>
					</c-table>
				</module>
			</c-form>
		</module>
	</page>
</template>

<script>
import {mapState} from 'vuex'
import {asyncMapState} from '@/store'

export default {
	name: 'm_inventory_detail',
	props: {
		id: String
	},
	components: {
		
	},
	computed: {
		...mapState(['systemConfig']),
		...asyncMapState(['DIC_INVENTORY_REASON'])
	},
	data() {
		return {
			warehouse_type:1,
			detailData:{},
			productData:[]
		}
	},

	watch: {
		id() {
			this.getOrderDetail();
			this.loadOrderProduct();
		}
	},

	mounted() {
		if(this.id){
			this.getOrderDetail();
			this.loadOrderProduct();
		}
	},

	methods: {
		//查询订单详情
		getOrderDetail() {
			this.request({
				url: '/wms/inventory/detail',
				data: {
					id:this.id
				},
				loading: false,
				success: data => {
					this.detailData = data;
					this.warehouse_type = data.warehouse_type;
					this.$refs.form.set(data);
				}
			});
		},
		//加载盘点产品列表
		loadOrderProduct(){
			this.request({
				url: '/wms/inventory/product_list',
				data: {id: this.id},
				success: data => {
					this.productData = data
				}
			});
		},
		submit(state) {
			//校验数据
			this.$refs.form.submit({
				url: '/wms/inventory/editOrAdd',
				rule: {
					warehouse_id: '请选择仓库',
					source_type: '请选择盘点类型',
					yg_id: '请选择操作员工',
					productData: [{
						type: 'function',  // 自定义函数校验
						fun: (value, data) => {
							if(this.productData.length > 0){
								var flag = true;
								this.productData.forEach(item => {
									if(!item.product_count || parseFloat(item.product_count) <= 0){
										flag = false;
									}
								});
								return flag;
							}else{
								return false;
							}
						},
						message: '请完善盘点商品明细'
					}]
				},
				dataFormatter: data => {
					data.id = this.id;
					data.state = state;
					data.product_list = this.productData;
					return data;
				},
				success: (data, res) => {
					this.$message({
						message: '操作成功',
						type: 'success'
					});
					this.$bus.$emit('inventoryList.update',{});//刷新订单列表
					//打开任务列表
					this.redirectTo('/inventory_list');
				}
			});
		}
	}
}
</script>